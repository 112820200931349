#nav {
    height: 8vh;
    background-color: var(--accent);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 1em;
}

#name {
    color: var(--text);
    margin: 0;
    font-size: 2em;
}

@media screen and (max-width: 767px) { 
    #name {
        font-size: 1em;
    }
  }

ul.nav-ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
    display: flex;
    justify-content: space-evenly;
    width: 15em;
}

@media screen and (max-width: 767px) { 
    ul.nav-ul {
        width: 9em;
    }
  }

a {
    text-decoration: none;
}

.nav-li {
    color: var(--text);
    font-size: 1em;
}
@media screen and (max-width: 767px) { 
    .nav-li {
        font-size: .7em;
    }
  }

a:link, a:visited {
    color: var(--text);
}