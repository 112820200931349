#header {
    margin-top: 0px;
    font-size: 1.6em;
    color: var(--text);
    text-align: center;
    margin-bottom: 1em;
    background-color: var(--accent);
    width: fit-content;
    margin-left: auto;
    padding: .4em;
    margin-right: auto;
}