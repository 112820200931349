
h3 {text-align: center;}

.events {
    background-color: var(--home);
}

.close {
    font-size: 1.5rem;
}
  
.col-12 img {
    cursor: pointer;
    margin: 1rem auto;
    width: 100%;
}

.col-12 img:hover {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.margin-top {
    margin-top: 3em;
}