#PortfolioEntry {
    text-align: center;
}

.project-name {
    margin: 0;
    font-size: 1.3em;
}

#portfolio-links {
    display: flex;
    justify-content: center;
}

.demo-photo {
    border: 2px solid var(--home);
    border-radius: 4px;
    padding: 5px;
    max-width: 150px;
    max-height: 150px;
    margin-top: 10px;
}

.portfolio-link {
    border: 2px black solid;
    background-color: var(--home);
    border-radius: 10px;
    padding: 5px;
    margin: 5px;
}

a.portfolio-link:link, a.portfolio-link:visited {
    color: var(--accent);
}

.divider {
    text-align: center;
    margin-bottom: 30px;
}

#PortfolioEntry .description {
    text-align: left;
    display: inline-block;
}