@import url('https://fonts.googleapis.com/css2?family=Dancing+Script:wght@700&family=Lato:ital,wght@1,400&family=Lustria&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Caveat:wght@700&family=Lato:ital,wght@1,400&family=Lustria&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Cabin:wght@700&family=Lato:ital,wght@1,400&family=Lustria&display=swap');


:root {
  --accent: #252525;
  --home: #f0f0f0;
  --text: #f0f0f0;
}

html{
  background-color: var(--accent);
}

p {
  text-align: left;
}

.App {
  background-color: var(--home);
  min-height: 100vh;
}

.main-content {
  margin: auto;
  padding-top: 3em;
}

.margin-bottom {
  margin-bottom: 5em;
}

.padding-bottom {
  padding-bottom: 5em;
}

@media screen and (min-width: 768px) { 
  .main-content {
    width: 60vw;
  }
}

@media screen and (max-width: 768px) { 
  .main-content {
    width: 90vw;
  }
}
